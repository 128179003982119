@tailwind base;
@tailwind components;
@tailwind utilities;
// @import '~react-toastify/scss/main.scss';
@import '~react-toastify/dist/ReactToastify.css';

:root {
  --foreground: rgb(23, 23, 23);
  --background: rgb(255, 255, 255);
  --border-color: rgb(101, 16, 9);
  --success-color: rgb(64, 22, 105);
  --error-color: rgb(34, 5, 54);
  --info-color: rgb(35, 22, 20);
  --warning-color: rgb(37, 81, 151);
  --primary-color: rgb(0, 18, 53);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground: #ededed;
    --background: #0a0a0a;
    --border-color: #6510109;
  }

  .btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #dedede;
  }

  .btn-info:hover {
    background-color: #138496;
    border-color: #117a8b;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.body {
  @apply text-base lg:text-lg 2xl:text-2xl 3xl:text-3xl;
  @apply leading-4 lg:leading-6 2xl:leading-8 3xl:leading-10;
  @apply h-screen scroll flex flex-col;
  @apply bg-gray-50 text-gray-900 dark:bg-gray-900 dark:text-gray-100;
  @apply bg-gradient-radial from-gray-200 to-gray-400 dark:from-gray-800 dark:to-gray-900;
  @apply bg-no-repeat bg-cover;
}

@media (prefers-color-scheme: dark) {
  .btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #dedede;
  }

  .btn-info:hover {
    background-color: #138496;
    border-color: #117a8b;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer base {
  .h1 {
    @apply font-extrabold text-center m-4 text-[1.5em] my-4;
  }

  .h2 {
    @apply font-semibold text-[1.3em] leading-[1.3em] my-4;
  }

  .h3 {
    @apply font-semibold my-2 text-[1em];
  }

  .h5 {
    @apply font-semibold tracking-tight text-gray-900 dark:text-white my-4;
  }

  .h9 {
    @apply font-bold text-center text-blue-600 drop-shadow-lg;
  }

  a {
    @apply text-cyan-800 dark:text-cyan-400;
  }

  p {
    @apply leading-relaxed my-4;
  }

  .ul,
  .li {
    @apply list-none p-0 m-0;
  }

  .disabled {
    @apply opacity-20 cursor-not-allowed;
  }

  .btn {
    @apply py-2 px-4 rounded-lg cursor-pointer font-semibold flex items-center justify-center gap-2;

    &.btn-primary {
      @apply bg-teal-700 text-gray-100 dark:text-gray-400;
      @apply hover:bg-teal-500 hover:text-white;
    }

    &.btn-secondary {
      @apply bg-gray-500 text-white;
      @apply hover:bg-gray-600 hover:text-gray-100;
    }

    &.btn-warning {
      @apply bg-orange-500 text-white;
      @apply hover:bg-orange-600 hover:text-white;
    }

    &.btn-danger {
      @apply bg-red-500 text-white;
      @apply hover:bg-red-600 hover:text-white;
    }

    &.btn-info {
      @apply bg-transparent text-gray-500 border border-gray-500;
      @apply hover:bg-transparent;
    }

    &.btn-banner {
      animation: pulse 1.42s infinite;

      @keyframes pulse {
        0% {
          opacity: 1;
          box-shadow:
            1px -3px 10px rgba(253, 230, 138, 1),
            -5px 6px 10px rgba(146, 64, 14, 1);
        }

        50% {
          opacity: 0.99;
          box-shadow:
            1px -3px 10px rgba(253, 230, 138, 0.9),
            -5px 6px 10px rgba(146, 64, 14, 0.9);
        }

        100% {
          opacity: 1;
          box-shadow:
            1px -3px 10px rgba(253, 230, 138, 1),
            -5px 6px 10px rgba(146, 64, 14, 1);
        }
      }

      @apply font-banner text-yellow-200;
      @apply rounded-full transition duration-1000 ease-in;

      // colors
      &.btn-banner-blue {
        color: rgb(167, 211, 232);
        background-color: rgb(19, 70, 98);
        box-shadow:
          1px -3px 10px rgb(10, 97, 121),
          -5px 6px 10px rgb(3, 32, 32);
      }

      &.btn-banner-green {
        color: rgb(200, 237, 190);
        background-color: rgb(19, 112, 20);
        box-shadow:
          1px -3px 10px rgb(19, 112, 20),
          -5px 6px 10px rgb(3, 55, 5);
      }

      &.btn-banner-yellow {
        color: rgb(237, 224, 155);
        background-color: rgb(104, 68, 13);
        box-shadow:
          1px -3px 10px rgba(253, 230, 138, 1),
          -5px 6px 10px rgba(146, 64, 14, 1);
      }

      &.btn-banner-red {
        color: rgb(255, 255, 255);
        background-color: rgb(203, 154, 130);
        box-shadow:
          1px -3px 10px rgb(216, 167, 87),
          -5px 6px 10px rgba(146, 64, 14, 1);
      }

      &.btn-banner-purple {
        color: rgb(199, 181, 241);
        background-color: rgb(74, 13, 123);
        box-shadow:
          1px -3px 10px rgb(139, 143, 246),
          -5px 6px 10px rgb(74, 13, 123);
      }

      &.btn-banner-orange {
        color: rgb(255, 204, 0);
        background-color: rgb(102, 50, 16);
        box-shadow:
          1px -3px 10px rgb(244, 196, 138),
          -5px 6px 10px rgb(117, 52, 12);
      }

      &.btn-banner-cyan {
        color: rgb(161, 217, 235);
        background-color: rgb(27, 105, 105);
        box-shadow:
          1px -3px 10px rgb(40, 206, 206),
          -5px 6px 10px rgb(5, 79, 79);
      }

      &.btn-banner-brown {
        color: rgb(239, 168, 168);
        background-color: rgb(87, 44, 13);
        box-shadow:
          1px -3px 10px rgb(130, 93, 45),
          -5px 6px 10px rgb(105, 54, 30);
      }
    }

    &.btn-coffee {
      @apply bg-yellow-900 text-white;
      @apply hover:bg-yellow-800 hover:text-white;
    }

    &.btn-partner {
      @apply bg-red-500 text-white;
      @apply hover:bg-red-600 hover:text-white;
    }

    &.btn-action {
      @apply bg-yellow-400 text-gray-900;
      @apply hover:bg-yellow-300;
    }

    &.btn-disabled {
      @apply opacity-20 cursor-not-allowed;
    }

    &.btn-icon {
      @apply rounded-full flex justify-center items-center;
      @apply w-6 h-6 p-1 text-[0.9em];
      @apply hover:bg-slate-300 cursor-pointer;
    }

    &.btn-icon-highlight {
      @apply rounded-full flex justify-center items-center;
      @apply w-10 h-10 p-1 text-[1.2em];
      @apply bg-yellow-300 text-gray-900;
    }
  }

  .avatar {
    @apply w-8 h-8 bg-cover rounded-sm;
    background-color: rgb(var(--background-end-rgb));

    &:hover {
      background-color: rgb(var(--background-start-rgb));
    }
  }
}

@layer components {
  input,
  textarea,
  select {
    @apply py-1;
    @apply w-full rounded-lg border-none;
    @apply bg-stone-200 dark:bg-stone-600;
  }

  // TODO: scope this to only calendar cards. and it's not workign well in dark mode.
  .card {
    @apply shadow-md;
    .card-header {
      @apply font-semibold p-2;
      @apply text-slate-800 dark:text-slate-200;
    }

    .card-body {
      @apply text-slate-900 dark:text-slate-100;
    }

    .card-footer {
      @apply px-2 py-1;
      @apply bg-opacity-80 dark:bg-opacity-80;
    }

    &.active {
      @apply border border-yellow-300 dark:border-yellow-600;
      box-shadow: 0 0 10px 0 rgb(171, 138, 7);
    }
    &#today {
      @apply border border-gray-700 dark:border-gray-300;
      box-shadow: 0 0 10px 0 rgb(255, 255, 255);
    }
    .card {
      @apply rounded-sm;
      .card-header {
        @apply text-slate-800 dark:text-slate-200;
      }
      .card-body {
        @apply bg-slate-100 dark:bg-slate-900;
      }
      .card-footer {
        @apply text-slate-800 dark:text-slate-200;
        @apply text-[0.9em];
      }
    }
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-icon {
    animation: spin 1s linear infinite;
  }

  .popover {
    @apply shadow-lg rounded-lg;
    @apply h-auto overflow-auto scroll p-4;
    @apply text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-800 leading-loose;
    @apply max-h-[70vh] max-w-[90vw];
  }

  .scroll {
    @apply scrollbar-thin scrollbar-thumb-gray-500 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent;
  }

  /* Custom styles for form-group alignment */
  .form-group {
    @apply grid grid-cols-12 gap-2 items-center;

    label {
      @apply col-span-5;
      @apply leading-tight;
    }

    > div {
      @apply col-span-7 px-1;
    }
  }

  .form-control.loading {
    background-image: url('/path/to/loader.gif');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px 20px;
    appearance: none;
  }

  textarea {
    &.workout-board {
      @apply text-[1em] font-handwriting tracking-widest leading-[1.5em];
      @apply mx-auto scroll rounded-lg border-none;
      @apply bg-stone-300 dark:bg-stone-700;
    }

    &.workout-notes {
      @apply font-handwriting tracking-widest text-[0.8em];
      @apply w-full scroll rounded-lg border-none;
      @apply bg-stone-300 dark:bg-stone-800;
    }
  }

  .range-slider {
    @apply w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-600;
  }
}

.toast-container {
  @apply z-50 mt-12 w-2/3 lg:w-1/2 xl:w-1/3 bg-opacity-60 hover:opacity-20 max-h-64 overflow-x-hidden overflow-y-auto scroll;

  .Toastify__toast-body {
    @apply items-start rounded-lg;

    .Toastify__toast-icon {
      @apply w-4 h-4 my-1;
    }
  }

  .Toastify__progress-bar--wrp {
    height: 2px;
  }
  .toast {
    @apply my-1 p-4 rounded-sm shadow-lg text-white;
  }

  .toast-success {
    color: var(--success-color);
  }

  .toast-error {
    color: var(--error-color);
  }

  .toast-info {
    color: var(--info-color);
  }

  .toast-warning {
    color: var(--warning-color);
  }
}

// clerk
.cl-navbar {
  > div:nth-child(1),
  > div:nth-child(3) {
    @apply hidden;
  }
}
.cl-rootBox {
  @apply bg-transparent;
  .cl-card {
    @apply bg-gray-200 dark:bg-gray-800 rounded-b-none;
    .cl-logoBox > a {
      @apply bg-yellow-300 rounded-full border border-yellow-300;
    }
    .cl-dividerLine {
      @apply bg-gray-400 dark:bg-gray-600;
    }
    * {
      @apply text-gray-900 dark:text-gray-100;
      button {
        @apply bg-gray-400 dark:bg-gray-600;
      }
    }
  }
  .cl-footer {
    .cl-footerAction {
      @apply bg-gray-300 dark:bg-gray-700;
      @apply w-full flex justify-center;
    }
  }
}
.cl-modalContent .cl-footer,
.cl-userButtonPopoverFooter,
.cl-footer > div:nth-child(2) {
  @apply hidden !important;
}

// chat
.chat-message-bubble {
  @apply shadow-md rounded p-4 mb-8 flex w-11/12;
  @apply text-slate-900 dark:text-slate-100;
  @apply border border-slate-200 dark:border-slate-800;
}

nextjs-portal {
  display: none !important;
}

// captcha
#rc-anchor-container {
  @apply bg-gray-200 dark:bg-gray-800;
}
